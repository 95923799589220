<template>
    <div>
        <b-row class="content-header">
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2>Terms of Use</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-card>
            <h5>REQUIREMENTS FOR USE OF THE SERVICE</h5>
            <p>
                We offer a service for people able to make a deal with us. The
                user must follow the rules of this website.
            </p>

            <h5>SERVICES</h5>
            <p>
                We agree to offer various user services. We reserve the right to
                change or disable any service at any time for any reason, or
                reduce your traffic according to our availability. A purchase of
                a premium account on <strong>Leechall.io</strong> grants access
                to private pages on the site, so changes or alterations to these
                pages may occur at any time without prior notice.
            </p>

            <h5>PERSONAL INFORMATION</h5>
            <p>
                The user warrants that the information given is complete,
                up-to-date, true and accurate. The user agrees to update his
                data if it changes in the future.
            </p>

            <h5>USER ACCOUNT</h5>
            <p>
                The User's account is for personal use only. Only the individual
                who holds the account is allowed to connect on it. Connections
                to the User's account are recorded so that we can detect account
                sharing. The User agrees to keep his login secret, We won't
                offer technical support for account hacking..
            </p>

            <h5>DOWNLOAD CONTENT</h5>
            <p>
                Illegal files & copyrighted material are absolutely forbidden to
                download. You claim full rights to download and use your
                downloaded content. We take no responsibility for any of your
                downloads! Our servers work as proxies and any download link
                will be disabled after a few hours.
            </p>

            <h5>INFRINGEMENTS</h5>
            <p>
                The user confirms not to disturb our services in any way, such
                as a server attack. The user may not try to circumvent the fixed
                limitations or violate our payment system. Reproduction, partial
                or complete, of the content of this website is prohibited
                without the consent of its owner. If the user violates one or
                more rules of the Terms of Use, Leechall.io reserves the right
                to block their account indefinitely.
            </p>

            <h5>SECURITY</h5>
            <p>
                If we find any user sharing account or leeching with Leechall.io
                account. We have all rights to permanently block the account
                without notice.
            </p>

            <h5>REFUND POLICY</h5>
            <p>
                Once the premium is activated we will not provide any kind of
                Refund, so please select your subscription accordingly. So
                please don't for a particular host because we can't gurantee
                uptime of that host.
            </p>

            <h5>SERVICE TERMS</h5>
            <p>
                The terms of service may be updated at any time and without
                prior notice. Users can check this page frequently.
            </p>
        </b-card>
    </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BCard,
    },
};
</script>
<style></style>
